/* eslint-disable no-undef */
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

import * as Sentry from "@sentry/react";
import "swiper/css";
import "swiper/css/pagination";

Sentry.init({
  dsn: "https://105d732a742dfc2fe440f6b51d0b4102@o4505883423735808.ingest.sentry.io/4505884383969280",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  ignoreErrors: [
    "This browser doesn't support the API's required to use the Firebase SDK",
    "gtag",
    "network request error",
    "Network request failed",
    "t.toLowerCase is not a function",
    "Failed to execute 'getComputedStyle'",
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Home = React.lazy(() => import("./components/Home"));
const Login = React.lazy(() => import("./components/Auth/Login"));
const MUALogin = React.lazy(() => import("./components/Auth/MUA"));
const Register = React.lazy(() => import("./components/Auth/Register"));
const CEmail = React.lazy(() => import("./components/Auth/CEmail"));
const ForgetPass = React.lazy(() => import("./components/Auth/ForgetPass"));
const AddDevice = React.lazy(() => import("./components/AddDevice"));
const Account = React.lazy(() => import("./components/Account"));

const Support = React.lazy(() => import("./components/Support"));
const TrackerSupport = React.lazy(() => import("./components/Support/Tracker"));
const Contact = React.lazy(() => import("./components/Contact"));
const OurApp = React.lazy(() => import("./components/OurApp"));

const Product = React.lazy(() => import("./components/Product"));
const Plans = React.lazy(() => import("./components/Plans"));
const Shop = React.lazy(() => import("./components/Shop"));

const Success = React.lazy(() => import("./components/Success"));
const MyDevices = React.lazy(() => import("./components/MyDevices"));
const Privacy = React.lazy(() => import("./components/Legal/Privacy"));
const Tos = React.lazy(() => import("./components/Legal/Tos"));
const Compliance = React.lazy(() => import("./components/Compliance"));
const Track = React.lazy(() => import("./components/Track"));
const History = React.lazy(() => import("./components/History"));
const Redirect = React.lazy(() => import("./components/Redirect"));
const FourZeroFour = React.lazy(() => import("./components/FourZeroFour"));
const About = React.lazy(() => import("./components/About"));
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const Referral = React.lazy(() => import("./components/Referral"));
const Business = React.lazy(() => import("./components/Business"));
const Share = React.lazy(() => import("./components/Track/Share"));
const HistoryManagement = React.lazy(() =>
  import("./components/HistoryManagement")
);

const Demo = React.lazy(() => import("./components/Demo"));

const WelcomeBox = React.lazy(() => import("./components/welcomebox"));

const WirelessTrackers = React.lazy(() =>
  import("./components/WirelessTrackers")
);
const WiredTrackers = React.lazy(() => import("./components/WiredTrackers"));
const Geofences = React.lazy(() => import("./components/Geofences"));

import getLocale from "./utils/locale";
import Navbar from "./components/Navbar";
import { fetchToken, onMessageListener } from "../firebase";
import { toast, Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollToTop from "./hooks/ScrollToTop";
import ErrorFallback from "./components/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { AuthContext } from "./Context/AuthContext";
import AssetTrackers from "./components/AssetTrackers";
import General from "./components/Account/Tabs/General";
import Billing from "./components/Account/Tabs/Billing";
import Developer from "./components/Account/Tabs/Developer";
import Referrals from "./components/Account/Tabs/Referrals";
import Sharing from "./components/Account/Tabs/Sharing";

const Lazy = ({ children }) => {
  return <React.Suspense fallback={<div></div>}>{children}</React.Suspense>;
};

function App() {
  const { i18n } = useTranslation();

  // let loggedInState = localStorage.getItem("safetag-username") ? true : false;
  // const [loggedIn, setLoggedIn] = React.useState(loggedInState);

  const { loggedIn, setLoggedIn } = React.useContext(AuthContext);
  const [locationConfirmed, setLocationConfirmed] = React.useState(true);
  const landing_page = true;
  const [notification, setNotification] = React.useState();

  onMessageListener()
    .then((payload) => {
      setNotification(payload);
      const token = localStorage.getItem("fcm_device_id");
      if (token)
        toast.message(payload.notification.title, {
          description: payload?.notification?.body,
        });
    })
    .catch((err) => console.log("failed: ", err));

  React.useEffect(() => {
    getLocale();
  }, []);

  document.addEventListener("snipcart.ready", () => {
    if (localStorage.getItem("user-prefsv2") != null) {
      var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
      Snipcart.api.session.setLanguage(user_prefs.language);
      Snipcart.api.session.setCurrency(user_prefs.currency.code.toLowerCase());
    }
  });

  window.addEventListener("located", () => {
    var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    i18n.changeLanguage(user_prefs.language);
  });

  window.addEventListener("locate_error", () => {
    setLocationConfirmed(false);
  });

  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false } },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ScrollToTop />
        {/* <Navigation setLoggedIn={setLoggedIn} loggedIn={loggedIn} /> */}
        <Navbar setLoggedIn={setLoggedIn} loggedIn={loggedIn} />
        <Toaster position="top-center" closeButton richColors />
        <Lazy>
          <WelcomeBox
            setLocationConfirmed={setLocationConfirmed}
            locationConfirmed={locationConfirmed}
          />
        </Lazy>
        <Routes>
          <Route
            path="/contact"
            element={
              <Lazy>
                <Contact />
              </Lazy>
            }
          />
          <Route
            path="/about"
            element={
              <Lazy>
                <About />
              </Lazy>
            }
          />

          <Route
            path="/tos"
            element={
              <Lazy>
                <Tos />
              </Lazy>
            }
          />
          <Route
            path="/privacy"
            element={
              <Lazy>
                <Privacy />
              </Lazy>
            }
          />
          <Route
            path="/support"
            element={
              <Lazy>
                <Support />
              </Lazy>
            }
          />
          <Route
            path="/support/trackers/:id"
            element={
              <Lazy>
                <TrackerSupport />
              </Lazy>
            }
          />
          <Route
            path="/success"
            element={
              <Lazy>
                <Success />
              </Lazy>
            }
          />
          <Route
            path="/compliance"
            element={
              <Lazy>
                <Compliance />
              </Lazy>
            }
          />

          <Route
            path="/plans"
            element={
              <Lazy>
                <Plans />
              </Lazy>
            }
          />

          <Route
            path="/product/:id"
            element={
              <Lazy>
                <Product />
              </Lazy>
            }
          />
          <Route
            path="/product"
            element={
              <Lazy>
                <Shop />
              </Lazy>
            }
          />
          <Route
            path="/shop"
            element={
              <Lazy>
                <Shop />
              </Lazy>
            }
          />
          <Route
            path="/wireless-trackers"
            element={
              <Lazy>
                <WirelessTrackers />
              </Lazy>
            }
          />
          <Route
            path="/wired-trackers"
            element={
              <Lazy>
                <WiredTrackers />
              </Lazy>
            }
          />
          <Route
            path="/asset-tracking"
            element={
              <Lazy>
                <AssetTrackers />
              </Lazy>
            }
          />
          <Route
            path="/our-app"
            element={
              <Lazy>
                <OurApp />
              </Lazy>
            }
          />
          <Route
            path="/referrals"
            element={
              <Lazy>
                <Referral />
              </Lazy>
            }
          />

          <Route
            path="/business"
            element={
              <Lazy>
                <Business />
              </Lazy>
            }
          />

          <Route
            path="/demo"
            element={
              <Lazy>
                <Demo />
              </Lazy>
            }
          />

          <Route
            path="/share/:id"
            element={
              <Lazy>
                <Share />
              </Lazy>
            }
          />

          <Route path="/assets/manuals/*" />
          <Route
            path="*"
            element={
              <Lazy>
                <FourZeroFour />
              </Lazy>
            }
          />

          <Route
            path="/forgetPass"
            element={
              <Lazy>
                <ForgetPass />
              </Lazy>
            }
          />

          {landing_page ? (
            <Route
              path="/"
              element={
                <Lazy>
                  <Home isLoggedIn={loggedIn} />
                </Lazy>
              }
            />
          ) : (
            <Route
              path="/"
              element={
                <Lazy>
                  <MyDevices />
                </Lazy>
              }
            />
          )}

          {loggedIn ? (
            <>
              <Route
                path="/add-device"
                element={
                  <Lazy>
                    <AddDevice setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/account/*"
                element={
                  <Lazy>
                    <Account setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              >
                <Route path="" element={<Navigate to="/account/general" />} />
                <Route path="general" element={<General />} />
                <Route path="billing" element={<Billing />} />
                <Route path="developer" element={<Developer />} />
                <Route path="referrals" element={<Referrals />} />
                <Route path="users" element={<Sharing />} />
                <Route path="*" element={<Navigate to="/account" />} />
              </Route>
              <Route
                path="/track"
                element={
                  <Lazy>
                    <Track />
                  </Lazy>
                }
              />
              <Route
                path="/history"
                element={
                  <Lazy>
                    <History />
                  </Lazy>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Lazy>
                    <Dashboard />
                  </Lazy>
                }
              />
              <Route
                path="/history-management"
                element={
                  <Lazy>
                    <HistoryManagement />
                  </Lazy>
                }
              />
              <Route
                path="/my-devices"
                element={
                  <Lazy>
                    <MyDevices />
                  </Lazy>
                }
              />
              <Route
                path="/my-geofences"
                element={
                  <Lazy>
                    <Geofences />
                  </Lazy>
                }
              />
              {/* ------------------- */}
              <Route
                path="/login"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/mua-accept"
                element={
                  <Lazy>
                    <MUALogin setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/signup"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/confirmEmail"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/forgetPass"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/login"
                element={
                  <Lazy>
                    <Login setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/mua-accept"
                element={
                  <Lazy>
                    <MUALogin setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/signup"
                element={
                  <Lazy>
                    <Register />
                  </Lazy>
                }
              />
              <Route
                path="/confirmEmail"
                element={
                  <Lazy>
                    <CEmail />
                  </Lazy>
                }
              />
              {/* ------------------ */}
              <Route
                path="/add-device"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/account"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/account/*"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/track"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/history"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/my-devices"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
              <Route
                path="/my-geofences"
                element={
                  <Lazy>
                    <Redirect setLoggedIn={setLoggedIn} />
                  </Lazy>
                }
              />
            </>
          )}
        </Routes>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
