import React from "react";
import { AuthContext } from "../../../../Context/AuthContext";
import { useMutation } from "react-query";
import { handleUpdateUser } from "../../../../utils/api";
import { useTranslation } from "react-i18next";
import { languageArray } from "../../../../constants/localization";
import { toast } from "sonner";
import {
  BsChevronDown,
  BsChevronUp,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { sleep } from "../../../../utils/time";

const UserInfo = ({ userDetails, refreshUser }) => {
  const [langTooltip, setLangTooltip] = React.useState(false);
  const [name, setName] = React.useState("");
  const [checked, setIsChecked] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [globalLanguage, setGlobalLanguage] = React.useState(null);
  const [nameError, setNameError] = React.useState(false);
  const [country, setCountry] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { refreshLogin } = React.useContext(AuthContext);
  const { t } = useTranslation();

  const error_message = React.useCallback(async (message) => {
    if (message) toast.error(message);
    else toast.error(t("account.errors.general"));
    await sleep(5000);
    setErrorMessage("");
  }, []);

  React.useEffect(() => {
    if (userDetails?.isSuccess) {
      setName(userDetails.data.name);
      setGlobalLanguage(userDetails.data.language);
      setEmail(userDetails.data.username);
      setIsChecked(userDetails.data?.contact);
      setCountry(
        Object.keys(JSON.parse(t("localization.main.countries"))).find(
          (k) =>
            JSON.parse(t("localization.main.countries"))[k] ===
            userDetails.data.country.toUpperCase()
        )
      );
    }
  }, [userDetails.isSuccess]);

  const update = useMutation({
    mutationFn: handleUpdateUser,
    onSuccess: () => {
      toast.success(t("account.errors.success"));
      // window.location.reload();
      refreshUser();
    },
    onError: async (e) => {
      if (e.response?.data?.message === "Insufficient permissions") return;
      if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        updateDetails();
      } else error_message();
    },
  });

  const updateDetails = async () => {
    if (name.length === 0 || name.length > 100) {
      setNameError(t("signup.main.error_name_length"));
      return;
    }

    let params = "?";
    if (checked !== userDetails.data?.contact)
      params += "contact=" + checked + "&";
    if (userDetails.data.name !== name) params += "name=" + name + "&";
    if (userDetails.data.language !== globalLanguage)
      params += "language=" + globalLanguage + "&";
    params = params.substring(0, params.length - 1);
    update.mutate({ params });
  };

  const updateName = (e) => {
    setNameError("");
    setName(e.target.value);
  };

  const [show, setShow] = React.useState(true);

  return (
    <div
      className={
        "mt-4 w-full rounded-xl border p-4 sm:p-8 flex flex-col sm:items-start gap-0" +
        (!show && "cursor-pointer")
      }
      onClick={() => setShow(true)}
    >
      <div className="flex flex-row items-center justify-between w-full ml-1">
        <h2 className="font-bold">{t("account.settings.title")}</h2>

        {show ? (
          <BsChevronUp
            onClick={(e) => {
              e.stopPropagation();
              setShow(false);
            }}
            className="cursor-pointer"
          />
        ) : (
          <BsChevronDown />
        )}
      </div>

      <div
        className="flex flex-col gap-4 sm:items-start transition-all duration-500 overflow-hidden px-px w-full"
        style={{
          maxHeight: show ? 540 : 0,
          marginTop: show ? 20 : 0,
        }}
      >
        <div className="flex flex-col gap-1 w-full">
          <label>{t("account.main.label_name")}</label>
          <div className="w-full bg-white py-1">
            <input
              onChange={updateName}
              value={name}
              className={
                "w-full h-12 bg-transparent border border-gray-300 rounded-md text-gray-900 " +
                (nameError && " border-red-400")
              }
              type="text"
              name="name"
              id="name"
            />
            {nameError && <p className="text-xs text-red-500">{nameError}</p>}
          </div>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <div className="flex flex-row items-center gap-2">
            <label>{t("account.main.label_language")}</label>
            <Tooltip
              id="account.main.language_tooltip"
              className="text-xs"
              noArrow
              delayHide={200}
              style={{
                fontSize: "0.7rem",
                padding: "0.2rem 0.5rem",
                maxWidth: 240,
              }}
            />

            <div
              data-tooltip-id="account.main.language_tooltip"
              data-tooltip-content={t("account.main.language_tooltip")}
            >
              <BsFillQuestionCircleFill className="text-pr hover:text-sr cursor-pointer duration-300 transition-all" />
            </div>
          </div>
          <select
            className="w-full my-1 h-12 bg-transparent border border-gray-300 rounded-md text-gray-900"
            onChange={(e) => setGlobalLanguage(e.target.value)}
            value={globalLanguage}
          >
            {languageArray.map((language, i) => (
              <option key={i} value={language.code}>
                {language.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>{t("account.main.label_email")}</label>
          <div className="font-semibold py-2">
            <p>{email}</p>
          </div>
        </div>

        <div>
          <label>{t("account.main.label_country")}</label>
          <div className="font-semibold py-2">
            <p>{country}</p>
          </div>
        </div>

        <div>
          <p className="text-black text-opacity-70 flex items-center gap-2 ml-0.5">
            <input
              onChange={(e) => setIsChecked(e.target.checked)}
              checked={checked}
              className="w-5 h-5 text-sr rounded border-gray-300 shadow-sm focus:border-pr focus:ring focus:ring-offset-0 focus:ring-pr focus:ring-opacity-50"
              type="checkbox"
            />
            {t("account.main.label_contact")}
          </p>
        </div>

        <div className="mt-2">
          {checked !== userDetails?.data?.contact ||
          userDetails.data.name !== name ||
          globalLanguage !== userDetails.data.language ? (
            <button
              onClick={updateDetails}
              disabled={update.isLoading || userDetails.isRefetching}
              className="w-full sm:w-fit px-4 py-3 border border-sr border-opacity-25 hover:border-opacity-100 transition-all duration-300 bg-white text-sr rounded-md disabled:opacity-40"
            >
              {update.isLoading
                ? t("geofences.button.saving")
                : t("geofences.button.save")}
            </button>
          ) : (
            <button
              className="w-full sm:w-fit px-4 py-3 border border-sr border-opacity-25 transition-all duration-300 bg-white text-sr rounded-md disabled:opacity-40"
              disabled={true}
            >
              {t("geofences.button.save")}
            </button>
          )}
        </div>
        <div className="text-[#616161] bg-[#E6E6E6] w-full px-4 py-1 mt-4 rounded text-xs">
          <p>{t("account.main.reset")}</p>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
