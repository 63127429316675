import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { handleUpdateAlertSchedule } from "../../../../utils/api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "sonner";

const DeleteScheduleModal = ({ isOpen, onClose, current, deleteIndex }) => {
  const { t } = useTranslation();
  const history = useNavigate();

  const qc = useQueryClient();
  const update = useMutation({
    mutationFn: handleUpdateAlertSchedule,
    onSuccess: () => {
      qc.invalidateQueries(["user-details"]);
      onClose();
      toast.success(t("account.schedule.success_delete"));
    },
    onError: async (e) => {
      if (e.response?.data?.message === "Insufficient permissions") return;
      const error_text = e?.response?.data?.message;
      if (error_text === "User does not exist.") {
        toast.error(t("account.errors.not_found"));
      } else if (error_text === "Incorrect username or password.") {
        toast.error(t("account.errors.incorrect"));
      } else if (error_text === "Unauthorized") {
        toast.error(t("account.errors.unauthorized"));
      } else if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        update.mutate({
          schedule: current.filter((_, i) => i !== deleteIndex),
        });
      } else {
        toast.error(t("account.schedule.error"));
      }
    },
  });

  if (!isOpen) return null;
  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-[420px] w-full relative rounded-xl shadow-md bg-white max-h-[90vh] flex flex-col overflow-hidden p-8"
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="text-lg font-semibold">
            {t("account.delete_schedule.title")}
          </div>

          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>

        <p className="text-sm text-opacity-70 text-black">
          {t("account.delete_schedule.message")}
        </p>

        <div className="flex flex-col-reverse md:flex-row-reverse justify-start gap-2 w-full mx-auto mt-8">
          <button
            className="flex flex-row items-center justify-center gap-2 bg-pr h-12 md:h-9 hover:bg-sr disabled:bg-neutral-500 rounded-md px-4 py-2 transition-all duration-300 text-white"
            onClick={() => {
              update.mutate({
                schedule: current.filter((_, i) => i !== deleteIndex),
              });
            }}
          >
            {/* {mutation.isLoading && (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
            {mutation.isLoading
              ? t("geofences.button.deleting") */}
            {t("geofences.button.delete")}
          </button>

          <button
            onClick={onClose}
            className="h-12 md:h-9 rounded-md py-1.5 px-3 text-pr bg-pr bg-opacity-5 border border-sr border-opacity-20 hover:bg-white transition-all duration-500"
          >
            {t("geofences.button.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteScheduleModal;
