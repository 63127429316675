import React from "react";
import { useOutletContext } from "react-router-dom";
import UserInfo from "./UserInfo";
import DeleteUser from "./DeleteUser";
import AlertsSchedule from "./AlertsSchedule";

const General = () => {
  const { userDetails, setLoggedIn, refreshUser } = useOutletContext();
  return (
    <div className="px-4 w-full">
        <UserInfo userDetails={userDetails} refreshUser={refreshUser} />
        <AlertsSchedule userDetails={userDetails} refreshUser={refreshUser} />
        <DeleteUser setLoggedIn={setLoggedIn} userDetails={userDetails} />
    </div>
  );
};

export default General;
