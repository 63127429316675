import moment from "moment";

export const getCountDown = (milliseconds) => {
  if (milliseconds < 0) {
    return "00:00";
  }
  let minutes = Math.floor(milliseconds / 60000);
  let seconds = ((milliseconds % 60000) / 1000).toFixed(0);
  return `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
};

export const dateFormater = (date, format) => {
  const formattedDate = moment(date).format(format);
  return formattedDate;
};

export const doubleDigit = (num) => {
  return num < 10 ? `0${num}` : num;
};

export const minutesToHrs = (minutes) => {
  return `${doubleDigit(Math.floor(minutes / 60))}:${doubleDigit(
    minutes % 60
  )} hr`;
  return `${Math.floor(minutes / 60)} hr ${minutes % 60} min`;
};

export const sleep = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};
