import React from "react";
import axios from "axios";
import { useNavigate } from "react-router";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  let username = localStorage.getItem("safetag-username") ? true : false;
  let idToken = localStorage.getItem("safetag-id_token") ? true : false;
  const [loggedIn, setLoggedIn] = React.useState(username && idToken);
  const navigate = useNavigate();

  const refreshLogin = async () => {
    try {
      let refToken = localStorage.getItem("safetag-ref_token");
      if (refToken === "in_session")
        refToken = sessionStorage.getItem("safetag-ref_token");
      const { data } = await axios.post(
        import.meta.env.VITE_PROD_API_URL + "/login/token",
        {},
        { headers: { Authorization: refToken } }
      );

      localStorage.setItem("safetag-id_token", data.IdToken);
    } catch (e) {
      if (
        e.response.data.message === "Refresh Token has been revoked" ||
        e.response.data.message === "Refresh Token has expired" ||
        e.response.data.message === "Invalid Refresh Token"
      ) {
        localStorage.removeItem("safetag-ref_token");
        sessionStorage.removeItem("safetag-ref_token");
        localStorage.removeItem("safetag-id_token");
        localStorage.removeItem("safetag-username");
      }

      setLoggedIn(false);
      navigate("/login");
    }
  };

  // React.useEffect(() => {
  //   refreshLogin();
  // }, []);

  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, refreshLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
