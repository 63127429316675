import React from "react";
import { useTranslation } from "react-i18next";
import Image from "./Image";

const AppSection = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full flex flex-col px-4 sm:px-8 lg:px-4 items-center py-24 -mb-4 z-[1] bg-pr relative overflow-hidden">
      <img
        src="/assets/home/map_bg.svg"
        className="absolute bottom-0 h-full md:translate-x-full left-0 w-1/2 object-cover z-0"
        loading="lazy"
      />
      <div className="grid lg:grid-cols-2 gap-8 w-full max-w-6xl relative z-[1]">
        <div className="flex flex-col items-start justify-center gap-8">
          <h2 className="text-white text-5xl font-bold text-center lg:text-left w-full">
            {t("product.app.title")}
          </h2>
          <p className="text-lg text-white text-opacity-70 text-center lg:text-left">
            {t("product.app.desc")}
          </p>

          <div className="flex flex-row w-full justify-center  lg:justify-start  items-center gap-4 mt-4 cursor-pointer">
            <CTAButtons />
          </div>
        </div>

        <div className="flex flex-col items-center max-w-lg mx-auto">
          <Image src="/assets/product/Asset_7.webp" />
        </div>
      </div>
    </section>
  );
};

const CTAButtons = () => {
  const { t } = useTranslation();
  return (
    <>
      <a
        className="w-auto"
        href="https://apps.apple.com/us/app/safetag-tracking/id1644524830"
      >
        {/* <div className="bg-black flex items-center justify-center py-3 px-6 hover:bg-opacity-75 transition-all rounded-lg"> */}
        <img
          src={t("home.app.appstore")}
          className="h-12 hover:opacity-75 transition-all"
        />
        {/* </div> */}
        {/* <img
            src={t("home.app.appstore")}
            alt="appstore"
            className="hover:opacity-70 transition-all w-full max-h-[55px] mx-auto object-contain"
            loading="lazy"
          /> */}
        <p className="text-gray-300 text-xs font-light ml-1">iOS 14.0+</p>
      </a>
      <a
        className="w-auto"
        href="https://play.google.com/store/apps/details?id=com.downriver.safetag"
      >
        {/* <div className="bg-black flex items-center justify-center py-3 px-6 hover:bg-opacity-75 transition-all rounded-lg"> */}
        <img
          src={t("home.app.playstore")}
          className="h-12 hover:opacity-75 transition-all"
        />
        {/* </div> */}
        <p className="text-gray-300 text-xs font-extralight ml-1">
          Android 10.0+
        </p>
      </a>
    </>
  );
};

export default AppSection;
