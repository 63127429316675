import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

const AddMember = ({ isOpen, onClose, mutation }) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [emailData, setEmailData] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setError(null);
    const currentuser = localStorage.getItem("safetag-username");
    if (email.trim() === "") return;
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!isValidEmail) {
      setEmailData(null);
      setError(t("account.users.validation1"));
    } else if (currentuser === email) {
      setError(t("account.users.validation2"));
    } else {
      setEmailData({
        id: email,
        role: "Viewer",
      });
    }
  }, [email]);

  React.useEffect(() => {
    if (!isOpen) setEmail("");
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-[520px] w-full relative rounded-xl shadow-md bg-white h-full sm:h-fit flex flex-col overflow-hidden p-4 sm:p-8"
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="text-lg font-semibold">{t("account.users.add")}</div>

          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-sm text-opacity-70 text-black font-medium">
            {t("account.users.add_label")}
          </p>

          <div className="relative">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder={t("account.users.add_placeholder")}
              className="w-full h-12 bg-transparent border border-gray-300 rounded-md text-gray-900 "
              type="email"
            />
            {emailData?.id && !error ? (
              <div className="flex flex-row gap-2 absolute top-1/2 right-0 -translate-y-1/2">
                <select
                  className="appearance-none font-semibold bg-transparent border-none outline-none focus:border-none focus:outline-none text-gray-900 h-10"
                  value={emailData.role}
                  onChange={(e) =>
                    setEmailData((p) => ({ ...p, role: e.target.value }))
                  }
                >
                  <option value="viewer">{t("account.users.viewer")}</option>
                  <option value="editor">{t("account.users.editor")}</option>
                </select>
              </div>
            ) : null}
          </div>
          <p className="text-xs text-red-500 -mt-1">{error}</p>
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse justify-start gap-2 w-full mx-auto mt-4">
          <button
            disabled={mutation.isLoading || !emailData?.id || error}
            className="flex flex-row items-center justify-center gap-2 bg-pr h-12 md:h-9 hover:bg-sr disabled:bg-neutral-500 rounded-md px-4 py-2 transition-all duration-300 text-white"
            onClick={() => {
              mutation.mutate(emailData);
            }}
          >
            {mutation.isLoading && (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
            {mutation.isLoading
              ? t("account.users.adding")
              : t("account.users.add")}
          </button>

          <button
            onClick={onClose}
            className="h-12 md:h-9 rounded-md py-1.5 px-3 text-pr bg-pr bg-opacity-5 border border-sr border-opacity-20 hover:bg-white transition-all duration-500"
          >
            {t("geofences.button.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
