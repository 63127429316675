import axios from "axios";
import { toast } from "sonner";

const permissionsErrorMsg = {
  en: "Insufficient Permissions: Please contact the account owner to make this update.",
  de: "Unzureichende Berechtigungen: Bitte wenden Sie sich an den Kontoinhaber, um diese Aktualisierung vorzunehmen.",
  fr: "Autorisations insuffisantes : veuillez contacter le propriétaire du compte pour effectuer cette mise à jour.",
  es: "Permisos insuficientes: comuníquese con el propietario de la cuenta para realizar esta actualización.",
  it: "Autorizzazioni insufficienti: contatta il proprietario dell'account per effettuare questo aggiornamento.",
};

export const permissionsError = (e) => {
  if (e.response?.data?.message === "Insufficient permissions") return true;
  return false;
};

const toastPermissionsError = () => {
  let lang = "en";
  let userPrefs = localStorage.getItem("user-prefsv2");
  if (userPrefs) {
    lang = JSON.parse(userPrefs).language;
  }
  toast.error(permissionsErrorMsg[lang]);
};

export const fromLocalStorage = () => {
  const username = localStorage.getItem("safetag-username");
  const token = localStorage.getItem("safetag-id_token");

  return { username, token };
};

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_PROD_API_URL,
  headers: { "Content-Type": "application/json" },
});

apiClient.interceptors.response.use(
  (response) => {
    if (response?.data?.message === "Insufficient permissions")
      toastPermissionsError();
    return response;
  },
  (e) => {
    if (permissionsError(e)) toastPermissionsError();
    return Promise.reject(e);
  }
);

apiClient.interceptors.request.use(
  (config) => {
    const { token } = fromLocalStorage();
    if (token) config.headers["Authorization"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
