import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CiCalendar } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { handleUpdateAlertSchedule } from "../../../../utils/api";
import { toast } from "sonner";
import { AuthContext } from "../../../../Context/AuthContext";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";

const doubleDigits = (num) => {
  if (typeof num === "string") num = parseInt(num);
  if (num < 10) return `0${num}`;
  return num.toString();
};

const getArrayRange = (start, end) => {
  let range = [];
  for (let i = start; i <= end; i++) {
    range.push(doubleDigits(i));
  }
  return range;
};

const onlyNumbers = (str, min, max) => {
  const str1 = str.replace(/\D/g, "").slice(0, 2);
  const num = parseInt(str1);
  if (isNaN(num)) return "";
  if (num > max) return doubleDigits(max);
  if (num < min && str1.length > 1) return doubleDigits(min);
  return str1;
};

const format24hr = (hour, minute, zone) => {
  if (!hour || !minute || !zone) return null;
  if (zone === "AM") {
    if (hour === "12") return `00:${doubleDigits(minute)}`;
    return `${doubleDigits(hour)}:${doubleDigits(minute)}`;
  } else {
    if (hour === "12") return `12:${doubleDigits(minute)}`;
    return `${doubleDigits(+hour + 12)}:${doubleDigits(minute)}`;
  }
};

const ScheduleModal = ({ isOpen, onClose, current, daysList }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [startHour, setStartHour] = React.useState("12");
  const [startMinute, setStartMinute] = React.useState("00");
  const [startZone, setStartZone] = React.useState("AM");
  const [start, setStart] = React.useState("");

  const [endHour, setEndHour] = React.useState("12");
  const [endMinute, setEndMinute] = React.useState("00");
  const [endZone, setEndZone] = React.useState("PM");
  const [end, setEnd] = React.useState("");

  const [days, setDays] = React.useState([0, 4, 6]);
  const { refreshLogin } = React.useContext(AuthContext);

  React.useEffect(() => {
    setStart(format24hr(startHour, startMinute, startZone));
    setEnd(format24hr(endHour, endMinute, endZone));
  }, [startHour, startMinute, startZone, endHour, endMinute, endZone]);
  const qc = useQueryClient();
  const update = useMutation({
    mutationFn: handleUpdateAlertSchedule,
    onSuccess: () => {
      qc.invalidateQueries(["user-details"]);
      onClose();
      toast.success(t("account.schedule.success"));
    },
    onError: async (e) => {
      if (e.response?.data?.message === "Insufficient permissions") return;
      const error_text = e?.response?.data?.message;
      if (error_text === "User does not exist.") {
        toast.error(t("account.errors.not_found"));
      } else if (error_text === "Incorrect username or password.") {
        toast.error(t("account.errors.incorrect"));
      } else if (error_text === "Unauthorized") {
        toast.error(t("account.errors.unauthorized"));
      } else if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        update.mutate({ schedule: [...current, { days, start, end }] });
      } else {
        toast.error(t("account.schedule.error"));
      }
    },
  });

  if (!isOpen) return null;
  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-xl w-full relative rounded-xl shadow-md bg-white max-h-[90vh] flex flex-col overflow-hidden p-4 sm:p-8"
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="text-lg font-semibold">
            {t("account.schedule_modal.title")}
          </div>

          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex flex-col p-2 sm:p-4 border rounded-md gap-4 col-span-2 sm:col-span-1">
            <p>{t("account.schedule_modal.from")}</p>
            <div className="flex flex-row items-center text-xl gap-2">
              <TimePicker
                range={getArrayRange(1, 12)}
                value={startHour}
                onChange={(v) => setStartHour(v)}
              />
              :
              <TimePicker
                range={getArrayRange(0, 59)}
                value={startMinute}
                onChange={(v) => setStartMinute(v)}
              />
              <div className="flex flex-row border rounded-full text-[10px] leading-3 items-center p-1 cursor-pointer select-none">
                <div
                  onClick={() => setStartZone("AM")}
                  className={`flex ${
                    startZone === "AM"
                      ? "bg-pr text-white"
                      : "bg-transparent text-black"
                  } p-1 rounded-full px-2`}
                >
                  AM
                </div>
                <div
                  onClick={() => setStartZone("PM")}
                  className={`flex ${
                    startZone === "PM"
                      ? "bg-pr text-white"
                      : "bg-transparent text-black"
                  } p-1 rounded-full px-2`}
                >
                  PM
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col p-2 sm:p-4 border rounded-md gap-4 col-span-2 sm:col-span-1">
            <p>{t("account.schedule_modal.to")}</p>
            <div className="flex flex-row items-center text-xl gap-2">
              <TimePicker
                range={getArrayRange(1, 12)}
                value={endHour}
                onChange={(v) => setEndHour(v)}
              />
              :
              <TimePicker
                range={getArrayRange(0, 59)}
                value={endMinute}
                onChange={(v) => setEndMinute(v)}
              />
              <div className="flex flex-row border rounded-full text-[10px] leading-3 items-center p-1 cursor-pointer select-none">
                <div
                  onClick={() => setEndZone("AM")}
                  className={`flex ${
                    endZone === "AM"
                      ? "bg-pr text-white"
                      : "bg-transparent text-black"
                  } p-1 rounded-full px-2`}
                >
                  AM
                </div>
                <div
                  onClick={() => setEndZone("PM")}
                  className={`flex ${
                    endZone === "PM"
                      ? "bg-pr text-white"
                      : "bg-transparent text-black"
                  } p-1 rounded-full px-2`}
                >
                  PM
                </div>
              </div>
            </div>
          </div>
          {start >= end && (
            <p className="text-red-500 text-xs mb-0 -translate-y-2 ">
              {t("account.schedule.invalid")}
            </p>
          )}
          <div className="flex flex-col p-2 sm:p-4 border rounded-md gap-4 col-span-2">
            <p>{t("account.schedule_modal.days")}</p>
            <div className="flex flex-col sm:flex-row sm:items-center text-xl gap-4 sm:gap-2 w-full justify-between">
              <div className="flex flex-row items-center gap-2">
                <CiCalendar />
                <div className="flex flex-row gap-1 text-[12px]">
                  {[1, 2, 3, 4, 5, 6, 0].map((day, i) => (
                    <div
                      key={i}
                      className={`w-6 h-6 ${
                        days.includes(day) ? `bg-pr text-pr` : ""
                      } font-semibold bg-opacity-30 rounded-full flex items-center justify-center cursor-pointer`}
                      onClick={() => {
                        if (days.includes(day)) {
                          setDays(days.filter((d) => d !== day));
                        } else {
                          setDays([...days, day]);
                        }
                      }}
                    >
                      {daysList[day]}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-row text-sm gap-2">
                <p>{t("account.schedule_modal.repeat")}</p>
                <div
                  onClick={() => {
                    if (days.length === 7) {
                      setDays([]);
                    } else {
                      setDays([0, 1, 2, 3, 4, 5, 6]);
                    }
                  }}
                  className={`w-14 h-6 rounded-full cursor-pointer select-none ${
                    days.length === 7 ? "bg-pr" : "bg-[#EEEEF0]"
                  } transition-all duration-300 relative`}
                >
                  <div
                    className={`top-1/2 -translate-y-1/2 right-1 ${
                      days.length === 7
                        ? "translate-x-0 bg-white"
                        : "-translate-x-8 bg-[#838383]"
                    } transition-all duration-300 h-4 w-4 absolute rounded-full`}
                  ></div>
                  <div
                    className={`top-1/2 -translate-y-1/2 left-3 text-white absolute text-[10px] leading-3 transition-all duration-300 ${
                      days.length === 7 ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    ON
                  </div>
                  <div
                    className={`top-1/2 -translate-y-1/2 right-2 text-[#838383] absolute text-[10px] leading-3 transition-all duration-300 ${
                      days.length === 7 ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    OFF
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-stretch sm:flex-row gap-2 sm:items-center justify-end w-full mt-4">
          <button
            onClick={onClose}
            className="py-2 px-5 rounded-md text-sm transition-all duration-500 bg-gray-100 hover:bg-gray-200"
          >
            {t("account.buttons.cancel")}
          </button>
          <button
            disabled={days.length === 0 || !start || !end || start >= end}
            onClick={() => {
              update.mutate({ schedule: [...current, { days, start, end }] });
            }}
            className="py-2 px-5 rounded-md text-sm transition-all disabled:opacity-30 duration-500 bg-pr hover:bg-sr text-white"
          >
            {t("account.schedule_modal.set")}
          </button>
        </div>
      </div>
    </div>
  );
};

const TimePicker = ({ range, value, onChange }) => {
  const scroll = React.useRef();
  const [open, setOpen] = React.useState(false);
  const update = (val) => {
    setOpen(false);
    onChange(val);
  };

  const scrollUp = () => {
    scroll.current?.scrollBy({ top: -44 * 3, behavior: "smooth" });
  };

  const scrollDown = () => {
    scroll.current?.scrollBy({ top: 44 * 3, behavior: "smooth" });
  };

  React.useEffect(() => {
    if (open) {
      const index = range.indexOf(value);
      scroll.current?.scrollTo(0, 44 * index - 96 + 22);
    }
  }, [open, value]);

  return (
    <div
      onClick={() => setOpen(!open)}
      className="w-14 py-1 text-center border rounded cursor-pointer relative"
    >
      {value}

      {open && (
        <div
          onClick={() => setOpen(false)}
          className="z-[10] fixed top-0 left-0 h-screen w-screen"
        ></div>
      )}
      {open && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.55)",
          }}
          className="absolute top-1/2 -translate-y-1/2 left-0 w-full bg-neutral-100 border h-48 overflow-hidden flex flex-col rounded-xl z-[11]"
        >
          <div
            onClick={scrollUp}
            className="flex flex-col h-8 items-center justify-center bg-gradient-to-b from-[#fff] to-[#fff8] absolute top-0 w-full"
          >
            <FaChevronUp className="text-pr" />
          </div>
          <div
            ref={scroll}
            className="flex flex-col items-center overflow-auto no-scrollbar"
          >
            {range.map((r, i) => (
              <div
                key={i}
                onClick={() => update(r)}
                className={`cursor-pointer p-2 w-full select-none ${
                  value === r
                    ? "bg-pr text-white hover:bg-pr"
                    : "hover:bg-red-200"
                }`}
              >
                {r}
              </div>
            ))}
          </div>
          <div
            onClick={scrollDown}
            className="flex flex-col h-8 items-center justify-center bg-gradient-to-t from-[#fff] to-[#fff8] absolute bottom-0 w-full"
          >
            <FaChevronDown className="text-pr" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleModal;
