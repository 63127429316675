import { currencyList, customerZones } from "../constants/localization";
import countryToCurrency from "country-to-currency";
import momentTz from "moment-timezone";
import { dateFormater, sleep } from "./time";
import { v4 } from "uuid";
import { getBrowserMetadata } from "./browser";
import devices_data from "../constants/track_demo_data.json";
import history_data from "../constants/history_demo_data.json";
import demo_data from "../constants/dashboard_demo_data.json";
import alerts_data from "../constants/alerts_demo_data.json";
import { apiClient, fromLocalStorage } from "./apiConfig";

export const handleLogin = async (formData) => {
  const { password, email: username } = formData;
  const { data } = await apiClient.post(`/login`, { username, password });
  return data;
};

export const handleJoinAccount = async ({
  account,
  username,
  password,
  name,
}) => {
  let { data } = await apiClient.post(
    `/multi-user/${username}/accept?password=${password}&name=${name}`,
    { password }
  );

  data.username = username;
  return data;
};

export const handleResendCode = async ({ username }) => {
  let { data } = await apiClient.post(`/users/resend/${username}`);
  return data;
};

export const handleRegister = async ({
  name,
  email,
  password,
  country,
  checked,
  personal,
  referred_by,
}) => {
  let zone;
  if (customerZones.UK.includes(country)) {
    zone = "UK";
  } else if (customerZones.EU.includes(country)) {
    zone = "EU";
  } else if (customerZones.US.includes(country)) {
    zone = "US";
  } else {
    zone = "other";
  }

  //Get currency from country dropdown
  let currency = countryToCurrency[country];
  if (currencyList[currency] === undefined) currency = "USD";

  //Update Local Storage with user selected values
  let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
  user_prefs.currency = currencyList[currency];
  user_prefs.customer_zone = zone;
  localStorage.setItem("user-prefsv2", JSON.stringify(user_prefs));

  let intl = Intl.DateTimeFormat().resolvedOptions();
  let timezone = JSON.parse(localStorage.getItem("user-localev2"))
    ? JSON.parse(localStorage.getItem("user-localev2")).timezone.id
    : intl.timeZone;
  let language = user_prefs ? user_prefs.language : "en";
  let customer_zone = user_prefs ? user_prefs.customer_zone : "UK";
  let { data } = await apiClient.post(
    `/users`,
    {
      name: name,
      username: email,
      password: password,
      country: country,
      contact: checked,
    },
    {
      params: {
        timezone,
        language,
        locale: intl.locale,
        customer_zone,
        personal_use: personal,
        referred_by,
      },
    }
  );

  return data;
};

export const handleConfirmEmail = async ({ username, confirmCode }) => {
  let { data } = await apiClient.post(
    `/users/confirm/${username}/${confirmCode}`
  );
  return data;
};

export const handleDeleteHistory = async ({ device }) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.delete(
    `/device/locations/${username}/${device}`
  );
  return data;
};

export const handleUpdateUser = async ({ params }) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/users/${username}${params}`);
  return data;
};

export const handleGetUserDetails = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/users/${username}`);
  return data;
};

export const handleUpdateEmailReports = async ({
  report_recipients,
  report_preferences,
}) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.patch(
    `/users/${username}`,
    {},
    {
      params: {
        report_recipients,
        report_preferences,
      },
    }
  );
  return data;
};

export const handleCreatePortal = async ({ userEmail }) => {
  const { data } = await apiClient.get(
    "/users/create-portal-session/" + userEmail + "/account"
  );

  return data;
};

export const handleDeleteAccount = async ({ username, password }) => {
  var data = JSON.stringify({
    username,
    password,
  });

  var config = {
    method: "delete",
    url: `/users`,
    data,
  };

  const response = await apiClient(config);
  return response.data;
};

export const handleDownloadData = async (startTime, endTime, device) => {
  const { username } = fromLocalStorage();
  const start = dateFormater(startTime, "YYYY-MM-DD");
  const end = dateFormater(endTime, "YYYY-MM-DD");
  const tz = momentTz.tz.guess();

  const { data } = await apiClient.get(
    `/device/locations/range/${username}/${device}?start=${start}&end=${end}&tz=${tz}`
  );
  return data;
};

export const handleGetDevices = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/device/${username}`);

  const customError = {
    response: {
      status: 500,
      data: {
        message: "invalid token",
      },
    },
  };

  //Stress Test

  // Concatenate updatedData onto itself 20 times
  /*
  const concatenatedData = Array(200).fill(data).flat();
  let filteredData = concatenatedData.filter((tracker) => tracker.location !== "");
  // Generate random string for each tracker's prefs.name
  const randomString = () => Math.random().toString(36).substring(7);
  const getRandomCoordinate = () => {
    const minLatitude = 49.823809;
    const maxLatitude = 58.635000;
    const minLongitude = -6.417000;
    const maxLongitude = 1.768189;
    const latitude = Math.random() * (maxLatitude - minLatitude) + minLatitude;
    const longitude = Math.random() * (maxLongitude - minLongitude) + minLongitude;
    return `${latitude},${longitude}`;
  };
  const updatedData = filteredData.map((tracker) => ({
    ...tracker,
    prefs: {
      ...tracker.prefs,
      name: randomString(),
    },
    status: {
      ...tracker.status,
      location: getRandomCoordinate(),
    },
  }));
  return updatedData;
  */

  // throw customError;
  return data;
};

export const handleGetDemoDevices = async () => {
  await sleep(1000);
  return devices_data;
};

export const handleAddDevice = async ({ tracker_id, tracker_name }) => {
  const { username } = fromLocalStorage();
  var config = {
    method: "patch",
    url: `/device/owner/${username}`,
    data: JSON.stringify({
      imei: tracker_id,
      name: tracker_name,
    }),
  };

  const { data } = await apiClient(config);
  return data;
};

export const handleSubmitContract = async ({ data, active_contract }) => {
  const { username } = fromLocalStorage();
  var config = {
    method: "patch",
    url: `/device/subscription/contract/${username}/${active_contract.id}`,
    data: data,
  };

  const response = await apiClient(config);
  return response.data;
};

export const handleLookupTracker = async ({ id }) => {
  const { username } = fromLocalStorage();

  var config = {
    method: "get",
    url: `/device/pricing/lookups/${username}?imei=${id}`,
  };

  const { data } = await apiClient(config);
  return data;
};

export const handleContact = async ({ data }) => {
  const response = await apiClient.post(`/contact`, data);
  return response.data;
};

export const handleFetchProducts = async ({ queryKey }) => {
  let ls_zone;
  if (localStorage.getItem("user-prefsv2") != null) {
    let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    ls_zone = user_prefs.customer_zone;
  }
  const { data } = await (ls_zone
    ? apiClient.get(`/product?customer_zone=${ls_zone}`, {
        params: queryKey[1],
      })
    : apiClient.get("/product", { params: queryKey[1] }));

  return data;
};

export const handleFetchProduct = async (props) => {
  const { data } = await apiClient.get("/product/" + props.queryKey[1]);
  return data;
};

export const handleFetchPricing = async (props) => {
  let ls_zone;
  if (localStorage.getItem("user-prefsv2") != null) {
    let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    ls_zone = user_prefs.customer_zone;
  }
  const { data } = await apiClient.get(
    `/zones/pricing/${props.queryKey[1]}/${ls_zone}`
  );

  return data;
};

export const handleFetchZone = async ({ id }) => {
  const { data } = await apiClient.get(`/zones${id}`);
  return data;
};

export const handleFetchZones = async (all = false) => {
  let ls_zone;
  if (localStorage.getItem("user-prefsv2") != null) {
    let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    ls_zone = user_prefs.customer_zone;
  }

  const { data } = await (ls_zone
    ? apiClient.get("/zones?customer_zone=" + ls_zone)
    : apiClient.get("/zones"));
  await sleep(0);
  return data;
};

export const handleFetchAllZones = async (all = false) => {
  const { data } = await apiClient.get("/zones");
  return data;
};

export const handleUpdateSubscription = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(
    `/users/create-portal-session/${username}/account?flow=payment_method_update`
  );
  return { data };
};

export const handleUpdateDeviceName = async ({ deviceName, devEditId }) => {
  let username = localStorage.getItem("safetag-username");
  let payload = {
    name: deviceName,
    device_id: devEditId,
  };
  payload = JSON.stringify(payload);
  const { data } = await apiClient.patch(`/device/name/${username}`, payload);
  return data;
};

export const handleRemoveDevice = async ({ devRemovalId }) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await apiClient.patch(
    `/device/remove/${username}?device_id=${devRemovalId}`
  );
  return data;
};

export const handleCancelSubscription = async ({
  devCancelId,
  reason,
  feedback,
}) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await apiClient.delete(`/device/subscription/${username}`, {
    params: {
      device_id: devCancelId,
      reason,
      feedback,
    },
  });

  return data;
};

export const handleOpenSubscriptionInvoice = async ({ device_id }) => {
  let username = localStorage.getItem("safetag-username");
  let { data } = await apiClient.get(
    `/device/subscription${username}/${device_id}`
  );
  return data;
};

export const handleFetchDeviceHistory = async (props) => {
  let username = localStorage.getItem("safetag-username");
  let id = props.queryKey[1];
  let date = dateFormater(props.queryKey[2], "YYYY-MM-DD");
  let tz = momentTz.tz.guess();
  const { data } = await apiClient.get(
    `/device/journey/date/${username}?device_id=${id}&date=${date}&tz=${tz}&platform=web`
  );

  // TESTING

  // const result = {
  //   865080043483979: history_data[0],
  //   865080043481106: history_data[1],
  //   865080043480900: history_data[2],
  // };

  // return result[props.queryKey[1]];

  // return history_data[2];
  return data;
};

export const handleGetDemoHistory = async () => {
  await sleep(1000);
  return history_data[Math.floor(Math.random() * history_data.length)];
};

export const handleEnableNotifications = async ({ token }) => {
  let username = localStorage.getItem("safetag-username");
  let device_id;
  if (localStorage.getItem("fcm_device_id")) {
    device_id = localStorage.getItem("fcm_device_id");
  } else {
    device_id = v4();
    localStorage.setItem("fcm_device_id", device_id);
  }

  let install = getBrowserMetadata();
  let { data } = await apiClient.post(`/users/fcm_id/${username}`, {
    token,
    device_id,
    install,
  });

  return { ...data, token };
};

export const handleDisableNotifications = async () => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await apiClient.delete(`/users/fcm_id/${username}`, {
    data: {
      token: localStorage.getItem("fcm_token"),
    },
  });
  return data;
};

export const handleResetPassword = async ({
  newPass,
  username,
  confirmCode,
}) => {
  const { data } = await apiClient.post(
    `/users/reset/${username}/${confirmCode}`,
    { password: newPass }
  );
  return data;
};

export const handleSendResetCode = async ({ username }) => {
  // TEST
  // throw {
  //   response: {
  //     data: {
  //       message:
  //         "Cannot reset password for the user as there is no registered/verified email or phone_number",
  //     },
  //   },
  // };
  const { data } = await apiClient.post(`/users/reset/${username}`);
  return data;
};

export const getZonePricing = async (device) => {
  const username = localStorage.getItem("safetag-username");
  const { data } = await apiClient.get(
    `/device/pricing/zones/${username}/${device._id}`
  );
  return data;
};

export const handleUpdateDevice = async ({
  device,
  subVal,
  selectedZoneId,
  refresh,
}) => {
  const username = localStorage.getItem("safetag-username");
  var url = `/device/subscription/${username}/${device._id}?cycle=${subVal}&coverage_zone=${selectedZoneId}`;
  if (refresh) url += `&refresh=${refresh}`;

  const { data } = await apiClient.patch(url);
  return data;
};

export const getDeviceAlerts = async (props) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await apiClient.get(
    `/device/alerts/${username}/${props.queryKey[1]}`
  );
  return data;
};

export const getTrackerAddress = async ({ deviceId, location_id }) => {
  if (deviceId === "demo") return { data: "demo" };

  let username = localStorage.getItem("safetag-username");
  let { data } = await apiClient.get(
    `/device/address/${username}/${deviceId}`,
    { params: { location_id } }
  );
  if (data === "No positioning data found.") {
    throw { message: "No positioning data found." };
  } else {
    return data;
  }
};

// Tracker Settings

export const changeTrackerName = async ({ device_id, name }) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/device/name/${username}`, {
    name,
    device_id,
  });

  return data;
};

export const changeTrackerGeoFence = async ({
  device_id,
  geofence_centre,
  geofence_size,
}) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/device/geofence/${username}`, {
    device_id,
    geofence_centre,
    geofence_size,
  });

  return data;
};

export const changeTrackerSpeedLimit = async ({ device_id, limit }) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/device/speed_limit/${username}`, {
    device_id,
    limit,
  });

  return data;
};

export const changeTrackerCategory = async ({
  device_id,
  name,
  selectedIcon,
}) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(
    `/device/category/${username}?device_id=${device_id}&category=${selectedIcon}`,
    { device_id, name }
  );

  return data;
};

export const changeTrackerMarker = async ({
  device_id,
  name,
  selectedMarker,
}) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(
    `/device/marker/${username}?device_id=${device_id}&marker=${selectedMarker}`,
    { device_id, name }
  );
  return data;
};

export const changeTrackerAlerts = async ({ device_id, alerts }) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/device/alerts/${username}`, {
    device_id,
    alerts,
  });

  return data;
};

export const verifyToken = async ({ token }) => {
  const { data } = await apiClient.post("/verify-token", {
    reCAPTCHA_TOKEN: token,
  });
  return data;
};

export const fetchGeofences = async () => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/geofences/${username}`);
  return data;
};

export const deleteGeofences = async ({ params }) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.delete(`/geofences/${username}`, {
    params,
  });
  return data;
};

export const updateDeviceGeofences = async ({ add, remove, deviceId }) => {
  let { username } = fromLocalStorage();
  await apiClient.patch(`/device/geofences/${username}`, {
    add,
    remove,
    devices: [deviceId],
  });
};

export const createNewGeofence = async ({ params }) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.post(`/geofences/${username}`, params);
  return data;
};

export const updateGeofence = async ({ id, params }) => {
  let { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/geofences/${username}`, params, {
    params: {
      ids: id,
    },
  });
  return data;
};

export const removeFCMToken = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.delete(`/users/fcm_id/${username}`, {
    data: { token: localStorage.getItem("fcm_token") },
  });

  return data;
};

export const handleStripe = async ({
  tracker_id,
  subVal,
  zone,
  currency_code,
  refreshRate,
}) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(
    "/device/subscription/" +
      username +
      "/" +
      tracker_id +
      "/" +
      subVal +
      "/add-device?coverage_id=" +
      zone.id +
      "&currency=" +
      currency_code.toLowerCase() +
      "&refresh=" +
      refreshRate
  );

  return data;
};

export const getAppToken = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/users/app_token/${username}`);
  return data;
};

export const handleGetTopSpeeds = async ({ queryKey }) => {
  const range = queryKey[1];
  let { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/stats/top-speeds/${username}`, {
    params: { range },
  });

  // return demo_data.topSpeed.data;
  return data;
};

export const handleGetAlerts = async (props) => {
  const { username } = fromLocalStorage();
  let page = props.pageParam || 1;
  const { data } = await apiClient.get(
    `/device/alerts/${username}/${props.queryKey[1]}`,
    { params: { page } }
  );
  return { data, nextCursor: data.length === 40 ? page + 1 : undefined };
};

export const createAppToken = async (expiry, expiryDate) => {
  const { username } = fromLocalStorage();
  let params = "";
  if (expiry) {
    params = `?expiry=${expiryDate}`;
  }
  const { data } = await apiClient.post(
    `/users/app_token/${username}${params}`
  );
  return data;
};

export const deleteAppToken = async (id) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.delete(`/users/app_token/${username}/${id}`);
  return data;
};

export const updateWebhook = async (url, secret) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.patch(`/users/webhook/${username}`, {
    url,
    secret,
  });
  return data;
};

export const deleteWebhook = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.delete(`/users/webhook/${username}`);
  return data;
};

export const testWebhook = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.post(`/users/webhook/${username}/test`);
  return data;
};

export const getDefaultConfig = async (id) => {
  const { data } = await apiClient.get(`/product/${id}/config`);
  return data;
};

export const handleRetainSubscription = async ({ device }) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.patch(
    `/device/subscription/coupon/${username}/${device}?coupon=first_cancel`
  );
  return data;
};

export const handleGetCredits = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/users/stripe/credit/${username}`);
  return data;
};

export const handleInviteReferral = async ({ email }) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.post(
    `/users/referral/invite/${username}/${email}`
  );
  return data;
};

export const handleUpdateAlertSchedule = async ({ schedule }) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.patch(
    `/users/quiet_schedule/${username}`,
    schedule.map((s) => ({ ...s, tz: momentTz.tz.guess() }))
  );

  if (data === "Error: Invalid Request Body.")
    throw { message: "Invalid Request Body." };
  return data;
};

export const handleGetSharePublic = async ({ queryKey }) => {
  const { data } = await apiClient.get(`/shares/public/${queryKey[1]}`);
  return data.filter(
    (share) =>
      share.status.coords.lat &&
      (share.status.coords.lon || share.status.coords.lng)
  );
};

export const handleGetShares = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/shares/${username}`);
  return data;
};

export const handleCreateShare = async ({ data }) => {
  const { username } = fromLocalStorage();
  const { data: response } = await apiClient.post(`/shares/${username}`, data);
  return response;
};

export const handleEditShare = async ({ data, id }) => {
  const { username } = fromLocalStorage();
  const { data: response } = await apiClient.put(
    `/shares/${username}/${id}`,
    data
  );

  return response;
};

export const handleDeleteShare = async ({ id }) => {
  const { username } = fromLocalStorage();
  const { data: response } = await apiClient.delete(
    `/shares/${username}/${id}`
  );
  return response;
};

export const handleFetchMultiUsers = async () => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.get(`/users/multi-user/${username}`);
  return data;
};

export const handleAddMultiUser = async ({ id, role }) => {
  const { username } = fromLocalStorage();
  let { data } = await apiClient.post(
    `/users/multi-user/${username}/${id}?role=${role}`
  );
  return { ...data, user: id };
};

export const handleDeleteMultiUser = async ({ id }) => {
  const { username } = fromLocalStorage();
  const { data } = await apiClient.delete(
    `/users/multi-user/${username}/${id}`
  );
  return data;
};

export const handleUpdateMultiUser = async ({ id, role }) => {
  const { username } = fromLocalStorage();
  let { data } = await apiClient.patch(
    `/users/multi-user/${username}/${id}?role=${role}`
  );
  return { ...data, user: id };
};
