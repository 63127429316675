import React from "react";
import { AuthContext } from "../../../../Context/AuthContext";
import { useMutation } from "react-query";
import { handleUpdateUser } from "../../../../utils/api";
import { useTranslation } from "react-i18next";
import { languageArray } from "../../../../constants/localization";
import { toast } from "sonner";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { MdAdd } from "react-icons/md";
import { CiCalendar, CiClock2 } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import ScheduleModal from "./ScheduleModal";
import DeleteScheduleModal from "./DeleteScheduleModal";

const days = ["S", "M", "T", "W", "T", "F", "S"];
const AlertsSchedule = ({ userDetails, refreshUser }) => {
  const { refreshLogin } = React.useContext(AuthContext);
  const [show, setShow] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { t } = useTranslation();

  const quiet_schedule = userDetails?.data?.quiet_schedule || [];

  return (
    <div
      className={
        "mt-4 w-full rounded-xl border p-4 sm:p-8 flex flex-col sm:items-start gap-0 " +
        (!show && "cursor-pointer")
      }
      onClick={() => setShow(true)}
    >
      <ScheduleModal
        daysList={days}
        current={quiet_schedule}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
      <DeleteScheduleModal
        current={quiet_schedule}
        deleteIndex={showDeleteModal}
        isOpen={showDeleteModal !== false}
        onClose={() => setShowDeleteModal(false)}
      />
      <div className="flex flex-row items-center justify-between w-full">
        <h2 className="font-bold">{t("account.schedule.title")}</h2>

        {show ? (
          <BsChevronUp
            onClick={(e) => {
              e.stopPropagation();
              setShow(false);
            }}
            className="cursor-pointer"
          />
        ) : (
          <BsChevronDown />
        )}
      </div>

      <div
        className="flex flex-col gap-4 sm:items-start transition-all duration-500 overflow-hidden px-px w-full"
        style={{
          // height: 500,
          maxHeight: show ? 500 : 0,
          marginTop: show ? 20 : 0,
        }}
      >
        <p className="opacity-60">{t("account.schedule.subheading")}</p>

        <div className="sm:border sm:rounded-xl h-fit overflow-hidden flex flex-col w-full mt-8">
          <div className="flex flex-col w-full overflow-hidden">
            <div className="flex flex-row pb-3 sm:p-3 items-center justify-between w-full sm:bg-gray-100">
              <p className="hidden sm:block">
                {t("account.schedule.schedule")}
              </p>

              <button
                onClick={() => {
                  if (quiet_schedule.length >= 5) {
                    toast.error(t("account.schedule.max"));
                    return;
                  }
                  setShowModal(true);
                }}
                className={`bg-pr ${
                  quiet_schedule.length < 5
                    ? "hover:bg-sr"
                    : "cursor-default opacity-60"
                } px-3 rounded-md py-2 text-white flex flex-row gap-2 items-center transition-all duration-500`}
              >
                <MdAdd /> {t("account.schedule.add")}
              </button>
            </div>

            <div className="flex flex-1 overflow-auto flex-col gap-3 sm:gap-0">
              {quiet_schedule.length === 0 && (
                <p className="sm:px-3 py-8 sm:text-center">
                  {t("account.schedule.empty")}
                </p>
              )}
              {quiet_schedule.map((item, index) => (
                <div
                  className="flex flex-row justify-between items-center border rounded-md sm:border-t sm:rounded-none sm:border-x-0 sm:border-b-0 p-3 py-4"
                  key={index}
                >
                  <div className="flex flex-1 flex-col-reverse sm:flex-row sm:items-center gap-2 sm:gap-10 lg:gap-20">
                    <div className="flex flex-row items-center gap-2">
                      <CiClock2 size={24} />
                      <div className="flex flex-row gap-2 ">
                        <p className="">{item.start}</p>
                        <p>to</p>
                        <p className="">{item.end}</p>
                      </div>
                    </div>

                    <div className="flex flex-row gap-2">
                      <CiCalendar size={24} />
                      <div className="flex flex-row gap-1 text-sm">
                        {[0, 1, 2, 3, 4, 5, 6].map((day, i) => (
                          <div
                            key={i}
                            className={`w-6 h-6 ${
                              item.days.includes(day)
                                ? "bg-pr text-pr"
                                : "text-gray-400"
                            } font-semibold bg-opacity-30 rounded-full flex items-center justify-center`}
                          >
                            {days[day]}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setShowDeleteModal(index);
                    }}
                  >
                    <RiDeleteBin6Line
                      size={24}
                      className="text-gray-500 cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="flex flex-row items-center justify-end w-full mt-4">
          <button className="btn-pr">{t("geofences.button.save")}</button>
        </div> */}
      </div>
    </div>
  );
};

export default AlertsSchedule;
