import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useOutletContext } from "react-router-dom";
import {
  deleteWebhook,
  testWebhook,
  updateWebhook,
} from "../../../../utils/api";
import { toast } from "sonner";
import useEscapeKey from "../../../../hooks/EscapeKey";
import { AuthContext } from "../../../../Context/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AiOutlineLoading, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteWebhookModal from "./modals/DeleteWebhook";
import { IoCopyOutline } from "react-icons/io5";
import { TiSpannerOutline } from "react-icons/ti";

const ToolTip = ({ children }) => (
  <p
    className={`absolute w-64 pointer-events-none z-[100] -top-1 left-1/2 -translate-x-1/2 -translate-y-full bg-stone-800 bg-opacity-90 delay-100 group-hover:delay-300 text-white text-xs opacity-0  rounded group-hover:opacity-100 transition-all duration-300 px-3 py-1`}
  >
    {children}
  </p>
);

const Webhooks = () => {
  const { userDetails, refreshUser } = useOutletContext();
  const { refreshLogin } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [state, setState] = React.useState(false);
  const schema = yup.object().shape({
    url: yup
      .string()
      .required(t("account.api.error_required"))
      .matches(
        /^(https?:\/\/)(www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/[\w\-\.]+)*(\?.*)?$/,
        t("account.api.error_invalid_url")
      ),
    secret: yup
      .string()
      .required(t("account.api.error_required"))
      .min(20, t("account.api.error_min"))
      .max(100, t("account.api.error_max")),
  });
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      url: userDetails?.data?.webhook?.url || "",
      secret: userDetails?.data?.webhook?.secret || "",
    },
  });

  React.useEffect(() => {
    reset({
      url: userDetails?.data?.webhook?.url || "",
      secret: userDetails?.data?.webhook?.secret || "",
    });
    setState((p) => !p);
  }, [userDetails]);

  const update = useMutation({
    mutationFn: async ({ data }) => {
      const res = await updateWebhook(data.url, data.secret);
      return res;
    },
    onSuccess: (data) => {
      window.location.reload();
      refreshUser();
      reset(getValues());
      setIsEdit(false);
    },
    onError: async (e) => {
      if (e.response?.data?.message === "Insufficient permissions") return;
      if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        update.mutate({ data: getValues() });
        return;
      } else toast.error(t("account.api.webhook_create_error"));
    },
  });

  const remove = useMutation({
    mutationFn: deleteWebhook,
    onSuccess: (data) => {
      reset({
        url: "",
        secret: "",
      });
      setDeleteModal(false);
      refreshUser();
    },
    onError: async (e) => {
      if (e.response?.data?.message === "Insufficient permissions") return;
      if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        remove.mutate();
        return;
      } else toast.error(t("account.api.webhook_delete_error"));
    },
  });

  const onSubmit = (data) => {
    update.mutate({ data });
  };

  const test = useMutation({
    mutationFn: testWebhook,
    onError: (e) => {
      toast.error(t("account.webhook.test_error"));
    },
  });

  useEscapeKey(() => setDeleteModal(false));

  return (
    <div className="sm:bg-neutral-100 sm:border rounded-md sm:p-4 flex flex-col">
      <DeleteWebhookModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        mutation={remove}
      />
      {userDetails?.isLoading || userDetails.isRefetching ? (
        <div className="py-8 flex flex-col items-center justify-center">
          <AiOutlineLoading className="text-sr text-2xl animate-spin" />
        </div>
      ) : userDetails?.data?.webhook?.url || isEdit ? (
        <div className="w-full flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <h2 className="font-medium">{t("account.api.webhook_setup")}</h2>
            {!isEdit && (
              <div className="hidden sm:flex flex-row gap-4 text-pr text-sm">
                <div
                  onClick={test.mutate}
                  className="flex flex-row gap-1 items-center hover:text-sr cursor-pointer relative group"
                >
                  {test.isLoading ? (
                    <AiOutlineLoading3Quarters className="animate-spin" />
                  ) : (
                    <TiSpannerOutline className="text-lg" />
                  )}

                  <p>{t("account.webhook.test")}</p>
                  <ToolTip position="left">
                    {t("account.webhook.test_tooltip")}
                  </ToolTip>
                </div>
                <div
                  onClick={() => setDeleteModal(true)}
                  className="flex flex-row gap-1 items-center hover:text-sr cursor-pointer"
                >
                  <RiDeleteBin6Line />
                  <p>{t("account.webhook.remove")}</p>
                </div>
                <div
                  onClick={() => setIsEdit(true)}
                  className="flex flex-row gap-1 items-center hover:text-sr cursor-pointer"
                >
                  <FiEdit2 />
                  <p>{t("account.webhook.edit")}</p>
                </div>
              </div>
            )}
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col py-2 gap-2"
          >
            <div className="flex flex-col gap-1">
              <label className="mt-2 text-sm">
                {t("account.api.webhook_url")}
              </label>
              <div className="w-full">
                {isEdit ? (
                  <input
                    disabled={!isEdit}
                    {...register("url")}
                    placeholder="https://yourdomain.com/webhook"
                    className={
                      "w-full px-3 h-12 bg-white border border-gray-300 rounded-md text-gray-900" +
                      (errors.url ? " border-red-400" : "")
                    }
                    type="text"
                  />
                ) : (
                  <div className="font-medium flex flex-row items-center gap-2">
                    {userDetails?.data?.webhook?.url}
                    <IoCopyOutline
                      className="hover:opacity-50 cursor-pointer transition-all duration-300"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          userDetails?.data?.webhook?.url
                        );
                      }}
                    />
                  </div>
                )}
                {errors.url && isEdit && (
                  <p className="text-xs text-red-500">{errors.url?.message}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="mt-2 text-sm">
                {t("account.api.webhook_secret")}
              </label>
              <div className="w-full">
                {isEdit ? (
                  <input
                    disabled={!isEdit}
                    className={
                      "w-full px-3 h-12 bg-white border border-gray-300 rounded-md text-gray-900 " +
                      (errors.secret ? " border-red-400" : "")
                    }
                    {...register("secret")}
                    placeholder="ST_dVmDyqYVukN..."
                  />
                ) : (
                  <div className="font-medium flex flex-row items-center gap-2">
                    {userDetails?.data?.webhook?.secret}{" "}
                    <IoCopyOutline
                      className="hover:opacity-50 cursor-pointer transition-all duration-300"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          userDetails?.data?.webhook?.secret
                        );
                      }}
                    />
                  </div>
                )}
                {errors.secret && isEdit && (
                  <p className="text-red-500 text-xs">
                    {errors.secret?.message}
                  </p>
                )}
              </div>
            </div>
            {isEdit && (
              <div className="flex flex-col sm:flex-row sm:items-center justify-end mt-4 gap-2">
                <button
                  onClick={() => setIsEdit(false)}
                  className="w-full sm:w-fit px-4 py-3 border transition-all duration-300 bg-neutral-200 hover:bg-white text-black text-opacity-70 rounded-md disabled:opacity-40"
                >
                  {t("geofences.button.cancel")}
                </button>
                <button
                  disabled={
                    errors.url || errors.secret || !isDirty || update.isLoading
                  }
                  type="submit"
                  className="bg-sr hover:bg-pr py-3 px-7 disabled:bg-gray-400 transition-all duration-500 rounded-lg text-white"
                >
                  {userDetails?.data?.webhook?.url
                    ? t("account.api.update_webhook")
                    : t("account.api.enable_webhook")}
                </button>
              </div>
            )}
          </form>
          {!isEdit && (
            <div className="flex sm:hidden flex-col gap-3 text-pr mt-4">
              <div
                onClick={() => setIsEdit(true)}
                className="flex flex-row gap-1 h-10 bg-white border rounded-lg justify-center items-center hover:text-sr cursor-pointer"
              >
                <FiEdit2 />
                <p>{t("account.webhook.edit")}</p>
              </div>
              <div
                onClick={() => setDeleteModal(true)}
                className="flex flex-row gap-1 h-10 bg-white border rounded-lg justify-center items-center hover:text-sr cursor-pointer"
              >
                <RiDeleteBin6Line />
                <p>{t("account.webhook.remove")}</p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex flex-col sm:flex-row sm:items-center gap-8 justify-between py-4">
          <div className="flex flex-col gap-2">
            <h2 className="font-medium">{t("account.api.webhook_setup")}</h2>
            <p className="text-sm opacity-70">
              {t("account.webhook.no_setup")}
            </p>
          </div>

          <button
            onClick={() => setIsEdit(true)}
            className="bg-pr hover:bg-sr py-3 px-7 disabled:bg-gray-400 transition-all duration-500 rounded-lg text-white"
          >
            {t("account.webhook.setup_webhook")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Webhooks;
