import React from "react";
import { Navigate, useOutletContext } from "react-router-dom";
import Webhooks from "./Webhooks";
import ApiKeys from "./ApiKeys";
import Docs from "./Docs";

const Developer = () => {
  const { userDetails } = useOutletContext();

  // if (userDetails?.data?.purpose !== "business") {
  //   return <Navigate to="/account" />;
  // }
  return (
    <div className="w-full px-4">
      <div className="w-full border rounded-lg flex flex-col mt-6 gap-6 p-4 sm:p-8">
        <Webhooks />
        <hr className="my-4" />
        <ApiKeys />
        <Docs />
      </div>
    </div>
  );
};

export default Developer;
